@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-enter {
  @apply opacity-0 transition;
  transform: perspective(60px) rotateX(-80deg) scale3d(0.1, 0.1, 1);
}
.modal-enter-active {
  @apply opacity-100 transition duration-300;
  transform: rotateX(0deg);
}
.modal-exit {
  @apply opacity-100;
  transform: rotateX(0deg) scaleX(1) scaleX(1);
}
.modal-exit-active {
  @apply opacity-0 duration-300;
  transform: perspective(60px) rotateX(80deg) scaleX(0.1) scaleY(0.1);
}
.modal-lg-enter {
  @apply opacity-0 transition;
  transform: perspective(200px) rotateX(-80deg) scale3d(0.1, 0.1, 1);
}
.modal-lg-enter-active {
  @apply opacity-100 transition duration-300;
  transform: rotateX(0deg);
}
.modal-lg-exit {
  @apply opacity-100;
  transform: rotateX(0deg) scaleX(1) scaleX(1);
}
.modal-lg-exit-active {
  @apply opacity-0 duration-300;
  transform: perspective(200px) rotateX(80deg) scaleX(0.1) scaleY(0.1);
}
.alert-enter {
  @apply transform scale-0;
}
.alert-enter-active {
  @apply transform scale-100 transition duration-200 ease-in-out;
}
.alert-exit {
  @apply transform scale-100;
}
.alert-exit-active {
  @apply transform scale-0 transition duration-200 ease-in-out;
}
.alert-icon-enter {
  @apply opacity-0 transform scale-0;
}
.alert-icon-enter-active {
  @apply opacity-100 transform scale-100 transition duration-200 ease-in-out;
}
.alert-icon-exit {
  @apply opacity-100 transform scale-100;
}
.alert-icon-exit-active {
  @apply opacity-0 transform scale-0 transition duration-200 ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0000001c;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00000055;
}

.loading-enter {
  @apply opacity-0;
}
.loading-enter-active {
  @apply opacity-100 transition duration-100 ease-in;
}
.loading-exit {
  @apply opacity-100;
}
.loading-exit-active {
  @apply opacity-0 transition duration-100 ease-out;
}
