.fade-exit {
  @apply transform rotate-0 opacity-100;
}
.fade-exit-active {
  @apply transform rotate-spin opacity-40 transition duration-200 ease-in;
}
.fade-enter {
  @apply transform rotate-0 opacity-40;
}
.fade-enter-active {
  @apply transform rotate-spin opacity-100 transition duration-200 ease-in;
}

.nav-menu-enter {
  @apply transform -translate-y-20 opacity-0;
}
.nav-menu-enter-active {
  @apply transform translate-y-5 opacity-100 transition duration-500 ease-out;
}
.nav-menu-exit {
  @apply transform translate-y-0 opacity-100;
}
.nav-menu-exit-active {
  @apply transform -translate-y-20 opacity-0 transition duration-500 ease-in-out;
}
